/********** Template CSS **********/
:root {
    --primary: #1f1767;
    --light: #F0FBFC;
    --dark: #181d38;
    --secondary:#06cc2c;
}

.fw-medium {
    font-weight: 600 !important;
}

.fw-semi-bold {
    font-weight: 700 !important;
}

.back-to-top {
    position: fixed;
    display: none;
    right: 45px;
    bottom: 45px;
    z-index: 99;
}
.emlimg{width: 200px;}
.text-right{text-align: right;}
.about{padding-top:100px;padding-bottom:30px}
.contact{padding-top:100px;padding-bottom:30px}
.feature{padding-top:100px !important;padding-bottom:30px}
.absec{object-fit : cover}
.condiv{height: 50px;width: 50px;}
.textdecodnone{text-decoration: none;} 

/*** Spinner ***/
#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}


/*** Button ***/
.cs-btn{font-size: 15px;}
.btn {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    transition: .5s;
}

.btn.btn-primary,
.btn.btn-secondary {
    color: #FFFFFF;
    background-color: #1f1767;
    border-color: #43a900;
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 0px;
}
.mailchimp input{
  padding: 8px 15px;
  border: 1px solid green;
  border-radius: 19px;
  font-size: 14px;
}
.mailchimp button{
  background: #42a903;
  color: #fff;
  padding: 7px 15px;
  border-radius: 5px;
  margin-left: 5px;
  border: 0;
  font-size: 14px;
}

/*** Navbar ***/
.navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    margin-left: 8px;
}

.navbar-light .navbar-nav .nav-link {
    margin-right: 30px;
    padding: 25px 0;
    color: #FFFFFF;
    font-size: 15px;
    text-transform: uppercase;
    outline: none;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
    color: #43a900;
}

@media (max-width: 991.98px) {
    .navbar-light .navbar-nav .nav-link  {
        margin-right: 0;
        padding: 10px 0;
    }

    .navbar-light .navbar-nav {
        border-top: 1px solid #EEEEEE;
    }
}

.navbar-light .navbar-brand,
.navbar-light a.btn {
    height: 75px;
}

.navbar-light .navbar-nav .nav-link {
    color: var(--dark);
    font-weight: 500;
}

.navbar-light.sticky-top {
    /* top: -100px; */
    top: 0;
    transition: .5s;
}

@media (min-width: 992px) {
    .navbar .nav-item .dropdown-menu {
        display: block;
        margin-top: 0;
        opacity: 0;
        visibility: hidden;
        transition: .5s;
    }

    .navbar .dropdown-menu.fade-down {
        top: 100%;
        transform: rotateX(-75deg);
        transform-origin: 0% 0%;
    }

    .navbar .nav-item:hover .dropdown-menu {
        top: 100%;
        transform: rotateX(0deg);
        visibility: visible;
        transition: .5s;
        opacity: 1;
    }
}


/*** Header carousel ***/
@media (max-width: 768px) {
    .header-carousel .owl-carousel-item {
        position: relative;
        min-height: 500px;
    }
    
    .header-carousel .owl-carousel-item img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.header-carousel .owl-nav {
    position: absolute;
    top: 50%;
    right: 8%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
}

.header-carousel .owl-nav .owl-prev,
.header-carousel .owl-nav .owl-next {
    margin: 7px 0;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background: transparent;
    border: 1px solid #FFFFFF;
    font-size: 22px;
    transition: .5s;
}

.header-carousel .owl-nav .owl-prev:hover,
.header-carousel .owl-nav .owl-next:hover {
    background: var(--secondary);
    border-color: var(--secondary);
}

.page-header {
    background: linear-gradient(rgba(24, 29, 56, .7), rgba(24, 29, 56, .7)), url(./../img/pageh.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.page-header-inner {
    background: rgba(15, 23, 43, .7);
}

.breadcrumb-item + .breadcrumb-item::before {
    color: var(--light);
}
/*======================
   Set Cookies
=======================*/
#cookie-bar{
  font-family: 'Montserrat', sans-serif;
  background: #232c41;
  display:block;
  /* position: fixed; */
  bottom: 0px;
  width: 100%;
  left: 0px;
  color: #fff;
  width: 100%;
}
#cookie-bar p{width: 85%;float: left;display: inline-block;font-size: 15px;line-height: 24px;color: #adadad;}
#cookie-bar button{float: right;background: transparent;border: 1px solid #fff;color: #fff;padding: 15px 20px;text-transform: uppercase;font-size: 16px;margin-top: 5px; border-radius: 4px;}
#cookie-bar a{color: #fff;}
#contentcookie{ padding:20px 50px; width: 80%; margin: auto; }
.adlear{text-align: center;
  border: 1px solid green;
  background: #fff;padding: 10px 15px;border-radius:10px}
.clear{ clear:both; }
      

  @media(max-width: 767px){

#cookie-bar p{width: 100%;font-size: 14px;}
#cookie-bar button{width: 100%;}
#cookie-bar a{color: #fff;}
#contentcookie{padding: 20px 0px;width: 90%;margin: auto;}
  }

/*** Section Title ***/
.section-title {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
}

.section-title::before {
    position: absolute;
    content: "";
    width: calc(100% + 80px);
    height: 2px;
    top: 4px;
    left: -40px;
    background: var(--primary);
    z-index: -1;
}

.section-title::after {
    position: absolute;
    content: "";
    width: calc(100% + 120px);
    height: 2px;
    bottom: 5px;
    left: -60px;
    background: var(--primary);
    z-index: -1;
}

.section-title.text-start::before {
    width: calc(100% + 40px);
    left: 0;
}

.section-title.text-start::after {
    width: calc(100% + 60px);
    left: 0;
}


/*** Service ***/
.service-item {
    background: var(--light);
    transition: .5s;
}

.service-item:hover {
    margin-top: -10px;
    background: var(--primary);
}

.service-item * {
    transition: .5s;
}

.service-item:hover * {
    color: var(--light) !important;
}


/*** Categories & Courses ***/
.category img,
.course-item img {
    transition: .5s;
}

.category a:hover img,
.course-item:hover img {
    transform: scale(1.1);
}


/*** Team ***/
.team-item img {
    transition: .5s;
}

.team-item:hover img {
    transform: scale(1.1);
}


/*** Testimonial ***/
.testimonial-carousel::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 1;
}

.testimonial-carousel::after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    height: 100%;
    width: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 1;
}

@media (min-width: 768px) {
    .testimonial-carousel::before,
    .testimonial-carousel::after {
        width: 200px;
    }
}

@media (min-width: 992px) {
    .testimonial-carousel::before,
    .testimonial-carousel::after {
        width: 300px;
    }
}

.testimonial-carousel .owl-item .testimonial-text,
.testimonial-carousel .owl-item.center .testimonial-text * {
    transition: .5s;
}

.testimonial-carousel .owl-item.center .testimonial-text {
    background: var(--primary) !important;
}

.testimonial-carousel .owl-item.center .testimonial-text * {
    color: #FFFFFF !important;
}

.testimonial-carousel .owl-dots {
    margin-top: 24px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.testimonial-carousel .owl-dot {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    width: 15px;
    height: 15px;
    border: 1px solid #CCCCCC;
    transition: .5s;
}

.testimonial-carousel .owl-dot.active {
    background: var(--primary);
    border-color: var(--primary);
}


/*** Footer ***/
.footer .btn.btn-social {
    margin-right: 5px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light);
    font-weight: normal;
    border: 1px solid #FFFFFF;
    border-radius: 35px;
    transition: .3s;
}

.footer .btn.btn-social:hover {
    color: var(--primary);
}

.footer .btn.btn-link {
    display: block;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: normal;
    text-transform: capitalize;
    transition: .3s;
    text-decoration:none
}

.footer .btn.btn-link::before {
    position: relative;
    content: ">";
    /* font-family: "Font Awesome 6 Free"; */
    font-weight: 900;
    margin-right: 10px;
}

.footer .btn.btn-link:hover {
    letter-spacing: 1px;
    box-shadow: none;
}

.footer .copyright {
    padding: 25px 0;
    font-size: 15px;
    border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright a {
    color: var(--light);
}

.footer .footer-menu a {
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid rgba(255, 255, 255, .1);
}

.footer .footer-menu a:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
}

/************Team css start***************/
/* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part {
  position: relative;
  z-index: 99;
  padding: 100px 0px 100px;
}

@media (max-width: 576px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part {
    padding: 70px 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part {
    padding: 70px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part {
    padding: 70px 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part {
    padding: 70px 0px;
  }
}

/* line 18, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_img {
 /* background-image: url("./img/learning_img_bg.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 70% 100%;
  background-position: left top;
  position: absolute;
  left: 0;
  bottom: 0;
  top: 147px;
  left: 82px
}

@media (max-width: 576px) {
  /* line 18, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_img {
    position: inherit;
    left: 0;
    bottom: 0;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 18, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_img {
    position: inherit;
    left: 0;
    bottom: 0;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 18, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_img {
    position: inherit;
    left: 0;
    bottom: 0;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 18, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_img {
    bottom: -43px;
    left: -80px;
  }
}

/* line 47, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_img img {
  padding: 0px 53px 64px 50px;
}

@media (max-width: 576px) {
  /* line 47, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_img img {
    padding: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 47, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_img img {
    padding: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 47, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_img img {
    padding: 0;
  }
}

@media (max-width: 576px) {
  /* line 64, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text {
    padding-left: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 64, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text {
    padding-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 64, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text {
    padding-left: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 64, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text {
    padding-left: 0;
  }
}

/* line 81, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_member_text h5 {
  font-family: "Roboto", sans-serif;
  color: #556172;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  padding-left: 75px;
  text-transform: uppercase;
}

/* line 90, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_member_text h5:after {
  position: absolute;
  left: 0;
  top: 10px;
  height: 2px;
  width: 60px;
  content: "";
  background-color: #3ca602;
}

/* line 101, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_member_text h2 {
  font-size: 42px;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 38px;
  position: relative;
  margin-top: 19px;
}

@media (max-width: 576px) {
  /* line 101, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text h2 {
    font-size: 25px;
    margin-bottom: 10px;
    line-height: 35px;
    margin-top: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 101, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text h2 {
    font-size: 25px;
    margin-bottom: 10px;
    line-height: 35px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 101, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text h2 {
    font-size: 25px;
    margin-bottom: 10px;
    line-height: 35px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 101, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text h2 {
    font-size: 28px;
    margin-bottom: 20px;
    line-height: 40px;
  }
}

/* line 137, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_member_text p {
  line-height: 1.929;
  margin-bottom: 7px;
}

@media (max-width: 576px) {
  /* line 137, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text p {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 137, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text p {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 137, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text p {
    margin-bottom: 20px;
  }
}

/* line 156, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_member_text ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 30px;
}

@media (max-width: 576px) {
  /* line 156, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 156, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 156, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul {
    margin-bottom: 20px;
  }
}

/* line 176, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_member_text ul li {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 14px;
  padding-left: 33px;
  padding-top: 12px;
  color: #888888;
}

@media (max-width: 576px) {
  /* line 176, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul li {
    padding-left: 41px;
    padding-top: 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 176, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul li {
    padding-left: 41px;
    padding-top: 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 176, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul li {
    padding-left: 41px;
    padding-top: 5px;
    display: block;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 176, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul li {
    padding-left: 41px;
  }
}

/* line 204, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_member_text ul li span {
  margin-right: 17px;
  font-size: 16px;
  position: absolute;
  left: 15px;
  padding-top: 2px;
}

@media (max-width: 576px) {
  /* line 204, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul li span {
    padding-bottom: 15px;
    position: absolute;
    left: 15px;
    padding-top: 12px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 204, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul li span {
    padding-bottom: 15px;
    position: absolute;
    left: 15px;
    padding-top: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 204, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul li span {
    padding-bottom: 15px;
    position: absolute;
    left: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 204, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul li span {
    padding-bottom: 15px;
    position: absolute;
    left: 15px;
    padding-top: 6px;
  }
}

/* line 241, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_member_text .btn_1 {
  margin-top: 6px;
}

/* line 247, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.advance_feature {
  padding: 100px 0px 100px;
}

@media (max-width: 576px) {
  /* line 247, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature {
    padding: 0px 0px 70px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 247, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature {
    padding: 0px 0px 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 247, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature {
    padding: 0px 0px 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 247, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature {
    padding: 70px 0px;
  }
}

/* line 261, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.advance_feature .learning_img {
  background-image: url("./../img/advance_feature_img.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 84% 100%;
  background-position: right top;
  left: 0;
  bottom: -31px;
  right: -82px;
  top: -30px;
}

@media (max-width: 576px) {
  /* line 261, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_img {
    position: inherit;
    top: 0px;
    left: 0;
    bottom: 0;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 261, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_img {
    position: inherit;
    left: 0;
    bottom: auto;
    top: 20px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 261, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_img {
    position: inherit;
    left: 0;
    bottom: 0;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 261, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_img {
    position: inherit;
    left: 0;
    bottom: 0;
    margin-bottom: 20px;
  }
}

/* line 295, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.advance_feature .learning_img img {
  padding: 0px 117px 0px 0px;
}

@media (max-width: 576px) {
  /* line 295, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_img img {
    padding: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 295, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_img img {
    padding: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 295, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_img img {
    padding: 0px;
  }
}



@media (max-width: 576px) {
  /* line 313, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_member_text_iner span {
    margin-top: 15px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 313, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_member_text_iner span {
    margin-top: 15px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 313, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_member_text_iner span {
    margin-top: 15px;
    margin-bottom: 10px;
  }
}

/* line 340, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.advance_feature .learning_member_text_iner .ti-stamp {
  background-color: #fff0e0;
}

/* line 343, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.advance_feature .learning_member_text_iner h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 17px;
}

@media (max-width: 576px) {
  /* line 343, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_member_text_iner h4 {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 343, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_member_text_iner h4 {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 343, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_member_text_iner h4 {
    margin-bottom: 10px;
  }
}

/**************** service_part css start ****************/
/* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
.member_counter {
  padding: 73px 0px 73px;
}

@media (max-width: 576px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter {
    padding: 50px 0px 60px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter {
    padding: 50px 0px 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter {
    padding: 50px 0px 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter {
    padding: 73px 0px 73px;
  }
}

/* line 22, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
.member_counter .single_member_counter {
  text-align: center;
}

@media (max-width: 576px) {
  /* line 22, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter {
    margin: 20px 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 22, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter {
    margin: 20px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 22, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter {
    margin: 20px 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 22, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter {
    margin: 20px 0px;
  }
}

/* line 41, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
.member_counter .single_member_counter img {
  width: 78px;
  height: 58px;
  display: block;
  margin-bottom: 8px;
  margin-left: -10px;
}

/* line 49, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
.member_counter .single_member_counter span {
  font-size: 60px;
  font-weight: 700;
  color: #fff;
  font-style: "Poppins", sans-serif;
}

@media (max-width: 576px) {
  /* line 49, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter span {
    line-height: 67px;
    font-size: 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 49, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter span {
    line-height: 67px;
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 49, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter span {
    line-height: 67px;
    font-size: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 49, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter span {
    line-height: 67px;
    font-size: 40px;
  }
}

/* line 76, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
.member_counter .single_member_counter h4 {
  color: #fff;
  font-size: 24px;
  font-size: 600;
  margin-top: 28px;
  text-transform: capitalize;
  position: relative;
}

/* line 84, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
.member_counter .single_member_counter h4:after {
  position: absolute;
  left: 0;
  top: -20px;
  right: 0;
  margin: 0 auto;
  width: 60px;
  height: 1px;
  background-color: #fff;
  content: "";
}

@media (max-width: 576px) {
  /* line 84, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter h4:after {
    top: -10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 84, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter h4:after {
    top: -10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 84, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter h4:after {
    top: -10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 84, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter h4:after {
    top: -10px;
  }
}

@media (max-width: 576px) {
  /* line 76, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter h4 {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 76, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter h4 {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 76, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter h4 {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 76, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter h4 {
    margin-top: 10px;
  }
}

/************Team css start***************/
/* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part {
  position: relative;
  z-index: 99;
  padding: 100px 0px 100px;
}

@media (max-width: 576px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part {
    padding: 70px 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part {
    padding: 70px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part {
    padding: 70px 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part {
    padding: 70px 0px;
  }
}

/* line 18, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_img {
 /* background-image: url("./img/learning_img_bg.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 70% 100%;
  background-position: left top;
  position: absolute;
  left: 0;
  bottom: 0;
  top: 147px;
  left: 82px
}

@media (max-width: 576px) {
  /* line 18, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_img {
    position: inherit;
    left: 0;
    bottom: 0;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 18, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_img {
    position: inherit;
    left: 0;
    bottom: 0;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 18, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_img {
    position: inherit;
    left: 0;
    bottom: 0;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 18, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_img {
    bottom: -43px;
    left: -80px;
  }
}

/* line 47, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_img img {
  padding: 0px 53px 64px 50px;
}

@media (max-width: 576px) {
  /* line 47, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_img img {
    padding: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 47, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_img img {
    padding: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 47, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_img img {
    padding: 0;
  }
}

@media (max-width: 576px) {
  /* line 64, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text {
    padding-left: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 64, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text {
    padding-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 64, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text {
    padding-left: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 64, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text {
    padding-left: 0;
  }
}

/* line 81, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_member_text h5 {
  font-family: "Roboto", sans-serif;
  color: #556172;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  padding-left: 75px;
  text-transform: uppercase;
}

/* line 90, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_member_text h5:after {
  position: absolute;
  left: 0;
  top: 10px;
  height: 2px;
  width: 60px;
  content: "";
  background-color: #3ca602;
}

/* line 101, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_member_text h2 {
  font-size: 42px;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 38px;
  position: relative;
  margin-top: 19px;
}

@media (max-width: 576px) {
  /* line 101, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text h2 {
    font-size: 25px;
    margin-bottom: 10px;
    line-height: 35px;
    margin-top: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 101, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text h2 {
    font-size: 25px;
    margin-bottom: 10px;
    line-height: 35px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 101, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text h2 {
    font-size: 25px;
    margin-bottom: 10px;
    line-height: 35px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 101, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text h2 {
    font-size: 28px;
    margin-bottom: 20px;
    line-height: 40px;
  }
}

/* line 137, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_member_text p {
  line-height: 1.929;
  margin-bottom: 7px;
}

@media (max-width: 576px) {
  /* line 137, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text p {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 137, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text p {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 137, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text p {
    margin-bottom: 20px;
  }
}

/* line 156, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_member_text ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 30px;
}

@media (max-width: 576px) {
  /* line 156, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 156, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 156, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul {
    margin-bottom: 20px;
  }
}

/* line 176, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_member_text ul li {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 14px;
  padding-left: 33px;
  padding-top: 12px;
  color: #888888;
}

@media (max-width: 576px) {
  /* line 176, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul li {
    padding-left: 41px;
    padding-top: 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 176, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul li {
    padding-left: 41px;
    padding-top: 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 176, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul li {
    padding-left: 41px;
    padding-top: 5px;
    display: block;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 176, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul li {
    padding-left: 41px;
  }
}

/* line 204, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_member_text ul li span {
  margin-right: 17px;
  font-size: 16px;
  position: absolute;
  left: 15px;
  padding-top: 2px;
}

@media (max-width: 576px) {
  /* line 204, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul li span {
    padding-bottom: 15px;
    position: absolute;
    left: 15px;
    padding-top: 12px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 204, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul li span {
    padding-bottom: 15px;
    position: absolute;
    left: 15px;
    padding-top: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 204, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul li span {
    padding-bottom: 15px;
    position: absolute;
    left: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 204, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul li span {
    padding-bottom: 15px;
    position: absolute;
    left: 15px;
    padding-top: 6px;
  }
}

/* line 241, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_member_text .btn_1 {
  margin-top: 6px;
}

/* line 247, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.advance_feature {
  padding: 100px 0px 100px;
}

@media (max-width: 576px) {
  /* line 247, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature {
    padding: 0px 0px 70px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 247, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature {
    padding: 0px 0px 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 247, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature {
    padding: 0px 0px 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 247, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature {
    padding: 70px 0px;
  }
}

/* line 261, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.advance_feature .learning_img {
  background-image: url("./../img/advance_feature_img.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 84% 100%;
  background-position: right top;
  left: 0;
  bottom: -31px;
  right: -82px;
  top: -30px;
}

@media (max-width: 576px) {
  /* line 261, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_img {
    position: inherit;
    top: 0px;
    left: 0;
    bottom: 0;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 261, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_img {
    position: inherit;
    left: 0;
    bottom: auto;
    top: 20px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 261, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_img {
    position: inherit;
    left: 0;
    bottom: 0;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 261, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_img {
    position: inherit;
    left: 0;
    bottom: 0;
    margin-bottom: 20px;
  }
}

/* line 295, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.advance_feature .learning_img img {
  padding: 0px 117px 0px 0px;
}

@media (max-width: 576px) {
  /* line 295, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_img img {
    padding: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 295, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_img img {
    padding: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 295, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_img img {
    padding: 0px;
  }
}

/* line 313, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.advance_feature .learning_member_text_iner span {
  height: 60px;
  width: 60px;
  line-height: 48px;
  border-radius: 50%;
  background-color: #44aa00;
  display: inline-block;
  text-align: center;
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #fff;
}

@media (max-width: 576px) {
  /* line 313, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_member_text_iner span {
    margin-top: 15px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 313, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_member_text_iner span {
    margin-top: 15px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 313, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_member_text_iner span {
    margin-top: 15px;
    margin-bottom: 10px;
  }
}

/* line 340, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.advance_feature .learning_member_text_iner .ti-stamp {
  background-color: #fff0e0;
}

/* line 343, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.advance_feature .learning_member_text_iner h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 17px;
}

@media (max-width: 576px) {
  /* line 343, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_member_text_iner h4 {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 343, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_member_text_iner h4 {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 343, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_member_text_iner h4 {
    margin-bottom: 10px;
  }
}

/**************** service_part css start ****************/
/* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
.member_counter {
  padding: 73px 0px 73px;
}

@media (max-width: 576px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter {
    padding: 50px 0px 60px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter {
    padding: 50px 0px 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter {
    padding: 50px 0px 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter {
    padding: 73px 0px 73px;
  }
}

/* line 22, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
.member_counter .single_member_counter {
  text-align: center;
}

@media (max-width: 576px) {
  /* line 22, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter {
    margin: 20px 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 22, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter {
    margin: 20px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 22, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter {
    margin: 20px 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 22, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter {
    margin: 20px 0px;
  }
}

/* line 41, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
.member_counter .single_member_counter img {
  width: 78px;
  height: 58px;
  display: block;
  margin-bottom: 8px;
  margin-left: -10px;
}

/* line 49, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
.member_counter .single_member_counter span {
  font-size: 60px;
  font-weight: 700;
  color: #fff;
  font-style: "Poppins", sans-serif;
}

@media (max-width: 576px) {
  /* line 49, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter span {
    line-height: 67px;
    font-size: 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 49, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter span {
    line-height: 67px;
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 49, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter span {
    line-height: 67px;
    font-size: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 49, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter span {
    line-height: 67px;
    font-size: 40px;
  }
}

/* line 76, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
.member_counter .single_member_counter h4 {
  color: #fff;
  font-size: 24px;
  font-size: 600;
  margin-top: 28px;
  text-transform: capitalize;
  position: relative;
}

/* line 84, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
.member_counter .single_member_counter h4:after {
  position: absolute;
  left: 0;
  top: -20px;
  right: 0;
  margin: 0 auto;
  width: 60px;
  height: 1px;
  background-color: #fff;
  content: "";
}

@media (max-width: 576px) {
  /* line 84, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter h4:after {
    top: -10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 84, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter h4:after {
    top: -10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 84, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter h4:after {
    top: -10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 84, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter h4:after {
    top: -10px;
  }
}

@media (max-width: 576px) {
  /* line 76, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter h4 {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 76, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter h4 {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 76, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter h4 {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 76, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter h4 {
    margin-top: 10px;
  }
}
/**************** service_part css start ****************/
/* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
.feature_part {
  padding-top: 65px;
}

/* line 5, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
.feature_part .single_feature_text h2 {
  font-size: 42px;
  line-height: 1.222;
  margin-bottom: 20px;
}

@media (max-width: 576px) {
  /* line 5, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_text h2 {
    margin-top: 0px;
    font-size: 25px;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 5, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_text h2 {
    margin-top: 0px;
    font-size: 30px;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 5, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_text h2 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 5, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_text h2 {
    margin-top: 0px;
    font-size: 35px;
  }
}

/* line 32, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
.feature_part .single_feature_text p {
  line-height: 1.8;
}

/* line 36, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
.feature_part .single_feature_text .btn_1 {
  margin-top: 45px;
}

@media (max-width: 576px) {
  /* line 36, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_text .btn_1 {
    margin-top: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 36, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_text .btn_1 {
    margin-top: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 36, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_text .btn_1 {
    margin-top: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 36, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_text .btn_1 {
    margin-top: 25px;
  }
}

/* line 57, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
.feature_part .single_feature_part {
  padding: 50px 20px 35px;
  border: 1px solid #f0f4f6;
  text-align: center;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

@media (max-width: 576px) {
  /* line 57, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_part {
    padding: 25px 10px;
    margin-top: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 57, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_part {
    padding: 30px 15px;
    margin-top: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 57, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_part {
    padding: 30px 25px;
    margin-top: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 57, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_part {
    margin-top: 25px;
  }
}

/* line 82, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
.feature_part .single_feature_part span {
  margin-bottom: 30px;
  display: inline-block;
  position: relative;
  z-index: 1;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  background-color: #f0f4f6;
  display: inline-block;
  line-height: 80px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

@media (max-width: 576px) {
  /* line 82, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_part span {
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 82, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_part span {
    margin-bottom: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 82, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_part span {
    margin-bottom: 35px;
  }
}

/* line 110, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
.feature_part .single_feature_part span i {
  color: #0c2e60;
  font-size: 24px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

/* line 116, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
.feature_part .single_feature_part h4 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
}

@media (max-width: 576px) {
  /* line 116, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_part h4 {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 116, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_part h4 {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 116, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_part h4 {
    margin-bottom: 15px;
  }
}

/* line 137, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
.feature_part .single_feature_part p {
  color: #7f7f7f;
  line-height: 1.8;
  font-size: 15px;
}

/* line 145, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
.feature_part .single_feature:hover .single_feature_part {
  border: 1px solid #3ca602;
}

/* line 148, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
.feature_part .single_feature:hover span {
  background-size: 200% auto;
}

/* line 152, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
.feature_part .single_feature:hover span i {
  color: #fff;
}

/* line 159, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
.single_feature_padding {
  padding-top: 140px;
}

@media (max-width: 576px) {
  /* line 159, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .single_feature_padding {
    padding-top: 70px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 159, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .single_feature_padding {
    padding-top: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 159, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .single_feature_padding {
    padding-top: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 159, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .single_feature_padding {
    padding-top: 70px;
  }
}
