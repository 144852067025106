.slick-arrow{display: none !important;}
.slick-dots{display: none !important;}
.text-primary {
  color: #43a900 !important;
}
.bg-primary {
  background-color: #1f1767 !important;
}
.category h5{color: #000;}
.fa-3x {
  font-size: 3em;
}
.d-menu{display: flex;}
* {
  margin: 0px;
  font-family: Rubik;
}

.LoginPageContainer {
  height: auto;
  
}

.LoginPageInnerContainer {
  display: flex;
  min-height: 100%;
}

.LoginPageInnerContainer .ImageContianer {
  width: 30%;
  background-color: #e1dfec;
  min-height: 100%;
  padding: 2%;

  justify-content: center;
  align-items: center;
}

.LoginPageInnerContainer .ImageContianer .GroupImage {
  width: 100%;
  display: block;
}

.LoginPageInnerContainer .LoginFormContainer {
  flex-grow: 2;
  background-color: white;
  min-height: 100%;
  padding: 0 5% 5% 5%;
  background: url(https://i.imgur.com/BKyjjFa.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.LoginPageInnerContainer .LoginFormContainer .LogoContainer .logo {
  height: 60px;
  margin-bottom: 30px;
}

.LoginPageInnerContainer .LoginFormContainer .header {
  font-size: 32px;
  font-weight: 500;
}

.LoginPageInnerContainer .LoginFormContainer .subHeader {
  color: #000;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 19px;
}

.LoginPageInnerContainer .LoginFormContainer .inputContainer {
  color: #3f3f45;
  margin: 20px 0px;
}

.LoginPageInnerContainer .LoginFormContainer .inputContainer .label {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-right: 7px;
  margin-bottom: 10px;
}

.LoginPageInnerContainer .LoginFormContainer .inputContainer .label .labelIcon {
  width: 20px;
  margin-right: 10px;
  display: block;
}

.LoginPageInnerContainer .LoginFormContainer .inputContainer .input {
  display: block;
  width: calc(100% - 20px);
  font-size: 15px;
  padding: 10px;
  border: 1px solid #d6d7db;
  border-radius: 5px;
  margin-top: 5px;
  outline: 0px !important;
}

.LoginPageInnerContainer .LoginFormContainer .OptionsContainer {
  display: flex;
  justify-content: space-between;
}

.LoginFormContainer {
  display: block;
  align-items: center;
}

.LoginFormInnerContainer {
  max-width: 100%;
}

.LoginPageInnerContainer .LoginFormContainer .checkbox {
  width: 15px;
  height: 15px;
  margin: 0px;
  display: block;
  border: 1px solid #d6d7db;
}

.LoginPageInnerContainer .LoginFormContainer .checkboxContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.LoginPageInnerContainer .LoginFormContainer .checkboxContainer label {
  display: block;
  padding: 0px 5px;
  color: #9aa4ad;
}

.LoginPageInnerContainer .LoginFormContainer .ForgotPasswordLink {
  color: #e7483b;
  text-decoration: none;
}

.LoginFormContainer .LoginFormInnerContainer .LoginButton {
  margin-top: 30px;
  display: block;
  width: 30%;
  margin: 0px auto;
  font-size: 16px;
  padding: 10px;
  border-radius: 20px;
  font-weight: bold;
  color: white;
  background-color: #2e1f7a;
  border: 0px;
  outline: 0px;
  cursor: pointer;
}

.LoginFormContainer .LoginFormInnerContainer .LoginButton:active {
  background-color: #4520ff;
}


@media only screen and (max-width: 1200px) {
  .LoginPageInnerContainer .ImageContianer {
      width: 50%;
  }
}

@media only screen and (max-width: 800px) {
  .LoginPageInnerContainer .ImageContianer {
      display: none;
  }

  .LoginFormContainer {
      justify-content: center;
  }
}
.LoginPageContainer::-webkit-scrollbar {
  width: 5px;
}

.LoginPageContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.LoginPageContainer::-webkit-scrollbar-thumb {
  background: #2e1f7a;
}

.LoginPageContainer::-webkit-scrollbar-thumb:hover {
  background: #4520ff;
} 
@media (max-width: 991px) {
  .d-menu{display: unset;} 
}